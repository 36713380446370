















import { Component, Vue } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsTabs from '@/components/VsTabs/Index.vue'

@Component({
    name: 'DevelopersIndex',
    components: {
        VsContainer,
        VsTabs,
    },
})
export default class extends Vue {
    get activeTab () {
        return this.$route.name
    }

    get tabs () {
        const tabs = [
            {
                label: this.$t('developers.tabs.apikey'),
                id: 'apikeyDevelopers',
                to: {
                    name: 'apikeyDevelopers',
                },
            },
            {
                label: this.$t('developers.tabs.integrations'),
                id: 'integrationsDevelopers',
                to: {
                    name: 'integrationsDevelopers',
                },
            },
            {
                label: this.$t('developers.tabs.goalTracking'),
                id: 'goalTrackingDevelopers',
                to: {
                    name: 'goalTrackingDevelopers',
                },
            },
        ]

        return tabs
    }
}
